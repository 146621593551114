import React from 'react'

const UserDashboard= React.lazy(() => import('./views/users/UserDashboard'))
const AdminDashboard= React.lazy(() => import('./views/admin/AdminDashboard'))
const AdminAllUsers= React.lazy(() => import('./views/admin/AdminAllUsers'))
const AdminAddUser= React.lazy(() => import('./views/admin/AdminAddUser'))
const AdminEditUser= React.lazy(() => import('./views/admin/AdminEditUser'))
const ActiveScans= React.lazy(() => import('./views/users/activeScans'))
const StartQuickScan= React.lazy(() => import('./views/users/startQuickScan'))
const ViewQuickScanReport= React.lazy(() => import('./views/users/viewQuickScanReport'))
const Agents = React.lazy(() => import('./views/users/agents'))
const ProjectVulnerabilities = React.lazy(() => import('./views/users/projectVulnerabilities'))
const AddMirroringProject= React.lazy(() => import('./views/users/addProject'))
const EditMirroringProject= React.lazy(() => import('./views/users/editProject'))

const HostsUnderProtection = React.lazy(() => import('./views/users/hostsUnderProtection'))
const AddHostUnderProtection = React.lazy(() => import('./views/users/addHostUnderProtection'))
const EditHostUnderProtection = React.lazy(() => import('./views/users/editHostUnderProtection'))
const HowToAddHostUnderProtection = React.lazy(() => import('./views/users/howToAddHostUnderProtection'))


const AddAgent= React.lazy(() => import('./views/users/addAgent'))
const Hosts= React.lazy(() => import('./views/users/hosts'))
const EndPoints= React.lazy(() => import('./views/users/endPoints'))
const Alerts= React.lazy(() => import('./views/users/alerts'))
const PIIData= React.lazy(() => import('./views/users/piiData'))
const PIIDataDetails= React.lazy(() => import('./views/users/piiDataDetails'))
const Protection= React.lazy(() => import('./views/users/protection'))
const Settings= React.lazy(() => import('./views/users/settings')) 

const routes = [
  { path: '/user-dashboard', name: 'User Dashboard', element: UserDashboard},
  { path: '/active-scans', name: 'Active Scans', element: ActiveScans}, 
  { path: '/start-active-scan', name: 'Start a Active Scan', element: StartQuickScan}, 
  { path: '/view-active-scan-report', name: 'View Active Scan Report', element: ViewQuickScanReport},
  { path: '/agents', name: 'Agents', element: Agents},
  { path: '/add-mirroring-project', name: 'AddMirroringProject', element: AddMirroringProject},
  { path: '/edit-mirroring-project', name: 'EditMirroringProject', element: EditMirroringProject},
  { path: '/agents', name: 'Agents', element: Agents},
  { path: '/add-agent', name: 'Add Agent', element: AddAgent},
  { path: '/project-vulnerabilities', name: 'Project vulnerabilities', element: ProjectVulnerabilities},
  { path: '/hosts', name: 'Hosts', element: Hosts},
  { path: '/endpoints', name: 'Endpoints', element: EndPoints},
  { path: '/alerts', name: 'Alerts', element: Alerts},
  { path: '/pii-data', name: 'PII Data', element: PIIData},
  { path: '/pii-data-details', name: 'PII Data Details', element: PIIDataDetails},
  { path: '/protection', name: 'Protection', element: Protection},
  { path: '/hosts-under-protection', name: 'Hosts Under Protection', element: HostsUnderProtection},
  { path: '/add-host-under-protection', name: 'Add Host Under Protection', element: AddHostUnderProtection},
  { path: '/edit-host-under-protection', name: 'Edit Host Under Protection', element: EditHostUnderProtection},
  { path: '/how-to-add-host-under-protection', name: 'How to Add Host Under Protection', element: HowToAddHostUnderProtection},
  { path: '/settings', name: 'Settings', element: Settings},   
  { path: '/admin-dashboard', name: 'Admin Dashboard', element: AdminDashboard}, 
  { path: '/admin-all-users', name: 'Admin All Users', element: AdminAllUsers}, 
  { path: '/admin-add-user', name: 'Admin Add User', element: AdminAddUser}, 
  { path: '/admin-edit-user', name: 'Admin Edit User', element: AdminEditUser}, 
]

export default routes 